.content p {
  font-family: "inter", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
}
.powered{
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_powered{
  margin-left: 20px;
}

.banner {
    background: linear-gradient(135deg, blueviolet, transparent), url('https://assets-global.website-files.com/66018e6989d33d79f2d87480/660194b6e06bc323b1ab3f9e_Sequence 01_1-transcode.mp4');
    height: 100vh;
    background-size: cover;
    background-position: center center;
    display: grid;
    place-items: center;
    text-align: center;
}
.banner video {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  height: 100vh;
  width: 100%;
  object-fit: cover;
  background-position: center center;
  display: grid;
  place-items: center;
  text-align: center;
}
.content {
  margin-top: 0;
  z-index: 5;
}
.content h1 {
  font-family: "Rochester", cursive;
  font-weight: 500;
  font-style: normal;
  margin: 0;
  z-index: 5;
  font-size: 80px;
  color: #fff;
}
.banner p {
  z-index: 5;
  margin: 50px;
  color: #fff;
}
.whatsapp {
  text-decoration: none;
  background: #800465;
  color: #fff;
  border: 2px solid white;
  padding: 19px 40px;
  border-radius: 50px;
  display: inline-block;
  margin-top: 15px;
  transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.whatsapp:hover {
  text-decoration: none;
  background: #ffffff;
  color: #000000;
  border: 2px solid rgb(81, 0, 85);
  padding: 19px 40px;
  border-radius: 50px;
  display: inline-block;
  margin-top: 15px;
}

@media (max-width: 991px) {
  body{
    padding: 0px !important;
  }
  .nav.openMenu {
    padding: 30px 0;
    background: rgba(0, 0, 0, 0.8);
    margin-top: 20px;
  }
  .nav.openMenu a {
    line-height: 2.5;
  }
}
@media (max-width: 800px) {
  .content h1 {
    font-size: 85px;
  }
}
@media (max-width: 600px) {
  .content h1 {
    font-size: 60px;
  }
  .openMenu {
    padding: 5px 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .openMenu a {
    color: #fff;
    font-size: 16px;
  }

  .banner {
    background-position: 100%;
  }
  .logo {
    font-size: 40px;
  }
  .nav ul li {
    margin: 5px 0;
  }
  .content h1 {
    font-size: 35px;
  }
}
@media (max-width: 450px) {
  .banner p {
    z-index: 5;
    margin: 20px;
    color: #fff;
}
  .openMenu {
    padding: 5px 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .openMenu a {
    color: #fff;
    font-size: 16px;
  }
  .content h1 {
    font-size: 40px;
  }
  .content p{
    font-size: 20px;
  }
}
.gradient {
  position: absolute;
  width: 100%;
  height: 100vh;
  opacity: 40%;
  background: hsla(292, 68%, 27%, 1);
  background: linear-gradient(
    90deg,
    hsla(292, 68%, 27%, 1) 0%,
    hsla(303, 93%, 73%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(292, 68%, 27%, 1) 0%,
    hsla(303, 93%, 73%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(292, 68%, 27%, 1) 0%,
    hsla(303, 93%, 73%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#661673", endColorstr="#FA7AF4", GradientType=1 );
}

.w-nav-link {
    vertical-align: top;
    color: #fff;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    text-decoration: none;
    display: inline-block;
    position: relative;
}

